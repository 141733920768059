.gold-prices .highlight {
    background-color: #ffeb3b; /* Daha dikkat çekici arka plan rengi */
    color: #000; /* Metin rengini değiştir */
    transition: background-color 1s ease-in-out, color 1s ease-in-out;
  }
  
  .gold-prices .table {
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .gold-prices .table th, .gold-prices .table td {
    text-align: center;
    vertical-align: middle;
  }
  
  .gold-prices .table thead-dark th {
    background-color: #343a40;
    color: white;
  }
  
  .gold-prices h1 {
    margin-top: 20px;
    font-size: 2.5rem;
    color: #343a40;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
  }
  
  @media (max-width: 768px) {
    .gold-prices .table th:nth-child(4),
    .gold-prices .table td:nth-child(4) {
      display: none;
    }
  }